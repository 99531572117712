import { FeatureFlagsStorage } from './feature-flags-storage'
import { type FeatureFlagsAvailable } from './types'

export type CheckType = 'all' | 'some'

export class HeimdallManager {
  constructor(private storage: { get: () => { featureFlags: FeatureFlagsAvailable[] } }) {}

  private static findKeys(key: FeatureFlagsAvailable) {
    return (featureFlags: FeatureFlagsAvailable[]) => featureFlags.includes(key)
  }

  private static findKey(key: FeatureFlagsAvailable, featureFlags: FeatureFlagsAvailable[]) {
    return this.findKeys(key)(featureFlags)
  }

  static hasFeatureFlag = (
    keys: FeatureFlagsAvailable[],
    featureFlags: FeatureFlagsAvailable[],
    checkType: CheckType = 'all'
  ) => {
    const statusType: Record<CheckType, boolean> = {
      all: keys?.every(key => this.findKey(key, featureFlags)),
      some: keys?.some(key => this.findKey(key, featureFlags))
    }

    return statusType[checkType]
  }

  pass(keys: FeatureFlagsAvailable[], checkType?: CheckType) {
    const { featureFlags } = this.storage.get()

    if (featureFlags.length === 0) return false

    return HeimdallManager.hasFeatureFlag(keys, featureFlags, checkType)
  }

  blocked(keys: FeatureFlagsAvailable[], checkType?: CheckType) {
    if (keys.length === 0) return false

    const { featureFlags } = this.storage.get()

    return HeimdallManager.hasFeatureFlag(keys, featureFlags, checkType) === false
  }
}

/* PUBLIC API */
export const Heimdall = new HeimdallManager(FeatureFlagsStorage)
