import { type FeatureFlagMap, type FeatureFlagsAvailable } from './types'

export const STORAGE_KEY = 'featureFlags'

type FeatureFlagsStorageProps = { identifiers: string[]; featureFlags: FeatureFlagsAvailable[] }

export const FeatureFlagsStorage = {
  set({ identifiers, featureFlags }: FeatureFlagsStorageProps) {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify({ identifiers, featureFlags }))
  },
  get(): FeatureFlagMap {
    const featureFlags = sessionStorage.getItem(STORAGE_KEY)

    return featureFlags ? JSON.parse(featureFlags) : { featureFlags: [], identifiers: [] }
  },
  clear() {
    sessionStorage.removeItem(STORAGE_KEY)
  }
}
