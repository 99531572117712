import { type AxiosError } from 'axios'

import { CHALLENGE_BLOCKED_PTBR } from '~/domains/stone-account/ports/stone-account.routes'
import {
  CHALLENGE_LOCKED,
  KYC_PENDING_REQUEST,
  NEED_VERIFICATION_ERROR,
  SIGN_IN_CHALLENGE_PENDING_APPROVAL,
  USER_IS_BLOCKED
} from '~/lib/constants'
import { isomorphicPush } from '~/lib/helpers/utils/isomorphic-push'

export async function forbiddenInterceptor(error: AxiosError) {
  if ([403, 422].includes(Number(error?.response?.status))) {
    const responseType = error?.response?.data?.type

    // We're ejecting to avoid console.eror on the challenge approval flow
    if (responseType === SIGN_IN_CHALLENGE_PENDING_APPROVAL) {
      return Promise.reject(error)
    }

    // We're ejecting to avoid console.eror on the otp verification flow
    if (responseType === NEED_VERIFICATION_ERROR) {
      return Promise.reject(error)
    }

    if ([KYC_PENDING_REQUEST, USER_IS_BLOCKED].includes(responseType)) {
      isomorphicPush(`/sair?shouldRedirect=true`, (error as any)?.config?.ctx)

      return Promise.reject(error)
    }

    if ([CHALLENGE_LOCKED].includes(responseType)) {
      isomorphicPush(CHALLENGE_BLOCKED_PTBR, (error as any)?.config?.ctx)

      return Promise.reject(error)
    }
  }

  return Promise.reject(error)
}
