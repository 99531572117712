import { type AxiosError, type AxiosResponse } from 'axios'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { UNAUTHORIZED_ERROR } from '~/lib/constants'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'
import { isomorphicPush } from '~/lib/helpers/utils/isomorphic-push'

export const responseUnauthorizedAccessInterceptor = (error: AxiosError) => {
  const response = error?.response as AxiosResponse

  if (Heimdall.blocked(['permission_unauthorized'])) {
    if ([403].includes(response?.status)) {
      /**
       * @disclaimer
       * Some endpoints may return 403 and this response status can mean that this entity
       * is not allowed to view a specific information, but it can access the rest of the feature.
       * Therefore, we're checking if the 403 status here isn't one of those endpoints returns.
       */
      const allowStatus403 = [/\prepayment-v[2-9].stone.com.br\/customer\/[0-9]{11,14}\/condition/, /action-tokens/]
      const is403Allowed = allowStatus403.some(regex => regex.test(response?.config.url as string))

      const { accountId } = isomorphicCookies.getAll()
      const isIdentityOwner = window.sessionStorage.getItem('isIdentityOwner')

      const isGuest = Boolean(isIdentityOwner) === false

      if ([UNAUTHORIZED_ERROR].includes(response?.data?.type) && isGuest && !is403Allowed) {
        isomorphicPush(`/unauthorized?identityId=${accountId}`, (error as any)?.config?.ctx)

        return Promise.reject(error)
      }
    }
  }

  return Promise.reject(error)
}
