import { type NextPageContext } from 'next'
import axios, {
  type AxiosError,
  type AxiosPromise,
  type AxiosRequestConfig,
  type AxiosResponse,
  type AxiosTransformer
} from 'axios'
import { type Merge } from 'type-fest'

import { authenticator } from '~/domains/platform/core/authenticator/authenticator'
import { logger } from '~/domains/platform/infra/monitoring/logger'
import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'
import { isomorphicCookies } from '~/lib/helpers/utils/cookies'
import { isomorphicPush } from '~/lib/helpers/utils/isomorphic-push'

import { allowStatus401Interceptor } from './interceptors/allow-status-401-interceptor'
import { forbiddenInterceptor } from './interceptors/forbidden-error-interceptor'
import { maintenanceErrorInterceptor } from './interceptors/maintenance-error-interceptor'

type Transformer = AxiosTransformer | AxiosTransformer[] | undefined
type SuccessResponse = ((value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>) | undefined
type ErrorResponse = (error: any) => any
type SuccessRequest = ((value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>) | undefined
export type HttpClientInstance = <T>(config?: CustomAxiosRequestConfig) => Promise<AxiosResponse<T>>

export type CustomAxiosRequestConfig = Merge<
  AxiosRequestConfig,
  { ctx?: Partial<NextPageContext>; haveToLog?: boolean; notPerformFetchWhen?: boolean }
>

export interface CustomAxiosError extends Omit<AxiosError, 'config'> {
  config: CustomAxiosRequestConfig
}

interface AsyncErrorReason {
  error: string
  path: string[]
}

export interface AsyncError {
  reason: AsyncErrorReason[]
  type: string
  message: string
}

interface HttpClientFactory {
  baseURL?: string
  transformResponse?: Transformer
  transformRequest?: Transformer
  requestInterceptor?: SuccessRequest
  responseSuccessInterceptor?: SuccessResponse
  responseErrorInterceptors: ErrorResponse[]
}

function _requestInterceptor(customInterceptor: SuccessRequest) {
  return async (config: CustomAxiosRequestConfig) => {
    try {
      const cookies = isomorphicCookies.getAll(config.ctx as NextPageContext)
      const { token } = cookies
      const deviceGeneratedId = clientSideOnly(() => Bucket.local.get('device::generated::id'))
      const platformId = clientSideOnly(() => Bucket.local.get('platform::id'))

      if (!token) return config

      const currentTokens = await authenticator.getValidTokens(config.ctx as NextPageContext)
      const validToken = currentTokens.token

      config.headers = {
        Authorization: `Bearer ${validToken}`,
        'device-generated-id': deviceGeneratedId,
        'platform-id': platformId,
        ...config.headers
      }

      if (customInterceptor) {
        const customConfig = customInterceptor(config)

        config = {
          ...config,
          ...customConfig
        }
      }

      return config
    } catch (error) {
      const err = error as any
      logger(JSON.stringify({ 'Error at requestInterceptor': err }))

      if (err?.status === 400 && err?.body?.error === 'invalid_grant') {
        isomorphicPush(`/sair?shouldRedirect=true`, config?.ctx)

        return Promise.reject(err)
      }

      if (axios.isAxiosError(err)) {
        return Promise.reject({ ...err, config })
      }

      return Promise.reject({ redirectToLogin: true, config })
    }
  }
}

function _responseSuccessInterceptor(customInterceptor: SuccessResponse) {
  return async (response: AxiosResponse) => {
    if (customInterceptor) {
      return customInterceptor(response)
    }

    return response
  }
}

function _responseErrorInterceptor(customInterceptor: ErrorResponse) {
  return async (error: AxiosError) => {
    const response = error?.response as AxiosResponse
    const haveToLog = (response?.config as CustomAxiosRequestConfig)?.haveToLog
    const avoidSameUrl = response?.config.url !== '/logr/report'

    if (haveToLog && avoidSameUrl) logger(error)

    if (customInterceptor) {
      return customInterceptor(error)
    }

    return Promise.reject(error)
  }
}

const DEFAULT_INTERCEPTORS = [allowStatus401Interceptor, forbiddenInterceptor, maintenanceErrorInterceptor]

export function httpClientFactory({
  baseURL,
  transformRequest = axios.defaults.transformRequest,
  transformResponse = axios.defaults.transformResponse,
  requestInterceptor,
  responseSuccessInterceptor,
  responseErrorInterceptors
}: HttpClientFactory): HttpClientInstance {
  const instance = axios.create({
    baseURL,
    transformRequest,
    transformResponse
  })

  instance.interceptors.request.use(_requestInterceptor(requestInterceptor))

  const ERROR_INTERCEPTORS = [...DEFAULT_INTERCEPTORS, ...responseErrorInterceptors]

  ERROR_INTERCEPTORS.forEach(responseErrorInterceptor =>
    instance.interceptors.response.use(
      _responseSuccessInterceptor(responseSuccessInterceptor),
      _responseErrorInterceptor(responseErrorInterceptor)
    )
  )

  return async <T>(config: CustomAxiosRequestConfig = {}) => instance({ haveToLog: true, ...config }) as AxiosPromise<T>
}
