import { type AxiosError } from 'axios'

import { Bucket } from '~/domains/platform/lib/bucket'
import { MAINTENANCE_ERROR } from '~/lib/constants'
import { isomorphicPush } from '~/lib/helpers'

export async function maintenanceErrorInterceptor(error: AxiosError) {
  if (error.response?.status === 503 && error.response?.data?.type === MAINTENANCE_ERROR) {
    isomorphicPush('/fora-de-servico', (error as any)?.config?.ctx)
    Bucket.cookies.set('maintenance::error::message', error.response.data.message)

    return Promise.reject(error)
  }

  if (error.response?.status === 503) {
    isomorphicPush('/manutencao', (error as any)?.config?.ctx)

    return Promise.reject(error)
  }

  return Promise.reject(error)
}
